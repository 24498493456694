import React from "react"
import { useStaticQuery, graphql } from "gatsby";
import styled from 'styled-components';


const StyledLogo = styled.div `
position: relative;
width: 240px;
height: 100px;
display: flex;
justify-content: center;
align-items: flex-start;
flex-direction:row;
 
z-index:0;

 ${({theme}) => theme.media.desktop} {
  width: 240px;
};`

const StyledH1 = styled.h1 `
position: relative;
color: black;
font-size: ${({theme}) => theme.font.size.m};
font-family: ${({theme}) => theme.font.family.Lobster};
line-height: 1.1;
margin: 1px;


${({theme}) => theme.media.desktop} {
    font-size: ${({theme}) => theme.font.size.m};
    margin-left: 5px;
}
`;

const StyledTitle=styled.div`
width: 120px;
height:80px;
margin-left: 3%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
 ${({theme}) => theme.media.desktop}{
width: 120px;
height:80px;
margin-left: 0;
margin-top: 2px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
 }
`
const IMG = styled.img`
position: relative;
width:85px;;
object-fit: cover;

`
const StyledWrapper=styled.div`
width:300px;
height:100px;
margin: 0;
display: flex;
justify-content: center;
align-items: flex-start;
`
const Logo = () => {
  const data = useStaticQuery(graphql`
  query{
  allDatoCmsLogo {
    nodes {
      id
      fotoLogo {
        url
      }
    }
  }
}
`)

return (

<>

       {data.allDatoCmsLogo.nodes.map(allDatoCmsLogo => (
         <StyledWrapper key={allDatoCmsLogo.id}>
        <StyledLogo>
        <IMG src={allDatoCmsLogo.fotoLogo.url} alt='Logo' />
        <StyledTitle>
        <StyledH1>Pasieka</StyledH1>
        <StyledH1>Cywińskich</StyledH1>
        </StyledTitle>
        </StyledLogo>
         </StyledWrapper>
       ))}

</>
)
}


export default Logo;