import React from 'react';
import styled from 'styled-components';
import IconFb from 'assets/images/IconFb.svg';
import IconMail from 'assets/images/IconMaiil.svg';
import IconTel from 'assets/images/IconTel.svg';
import IconLoc from 'assets/images/IconLoc.svg';

const StyledSocial = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  ${({ theme }) => theme.media.desktop} {
    width: 20%;
    min-width: 280px;
    margin: 0 auto;
    margin-top: 5px;
    height: 40px;
    display: flex;
    justify-content: space-between;
  }
`;

const Fb = styled(IconFb)`
  width: 35px;
  height: 35px;
`;

const Mail = styled(IconMail)`
  width: 35px;
  height: 35px;
`;

const Tel = styled(IconTel)`
  width: 35px;
  height: 35px;
`;

const Loc = styled(IconLoc)`
  width: 35px;
  height: 35px;
`;
const A = styled.a`
  display: block;
`;

const SocialMenu = () => (
  <StyledSocial>
    <div>
      {' '}
      <A href="https://pl-pl.facebook.com/pasieka.cywinskich/">
        <Fb />
      </A>
    </div>
    <div>
      {' '}
      <A href="mailto:pasiekacywinskich@gmail.com">
        <Mail />
      </A>
    </div>
    <div>
      {' '}
      <A href="tel:+48661914411">
        <Tel />
      </A>
    </div>
    <div>
      {' '}
      <A href="https://goo.gl/maps/3WecRUGfqqvq5CxJA">
        <Loc />
      </A>
    </div>
  </StyledSocial>
);
export default SocialMenu;
