import React, {useEffect, useState} from 'react';
import calculateOrderQuanity from "src/utlis/calculateOrderQuanity"

// crate a order context
const OrderContext = React.createContext({
    deliver: [],
});

export function OrderProvider({children}) {

    const initialFormState = {name: '', price: 0, payment: ''};
    const initialPayState = {payment: ''};
    const [order, setOrder] = useState([]);
    const [deliver, SetDeliver] = useState(initialFormState)
    const [checkDelivery, setCheckDelivery] = useState(false);
    const [checkForm, setCheckForm] = useState(null);
    const [isValidForm, setValidForm] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false)
    const [isModalInpostOpen, setModalInpostOpen] = useState(false)
    const [quanity, setQuanity] = useState(0)
    const [selectedpoint, setSelectedPoint] = useState([]);
    const [payment, setPayment] = useState(initialPayState);
    const [buyed, SetBuyed] = useState({modal:'', id:''});

 /*   useEffect((values) => {
        console.log('setBuyeduseeffeOrdercionstex')
        if(typeof values.id === "undefined" ){
            SetBuyed({...buyed,modal:values.modal })
        }else {
            SetBuyed({modal: values.modal, id: values.id})
        }
    }, [order.length])*/

    const handleSetBuyed = (values) => {
        if(typeof values.id === "undefined" ){
            SetBuyed({...buyed,modal:values.modal })

        }else {
            SetBuyed({modal: values.modal, id: values.id})

        }
    }

    const handleSetDelivery = (values) => {
        SetDeliver({
            ...deliver,
            name: values.name,
            price: values.price,
            payment: values.payment
        });
    };
    const handleSetCheck = (values) => {
        setCheckForm(values)
    }

    const handleSetValidForm = (values) => {
        setValidForm(values)
    }
    const handleCheckDelivery = (values) => {
        setCheckDelivery(values)
    }
    const toogleModalOpen = () => {
        setModalOpen(!isModalOpen)
    }
    const toogleModalInpostOpen = () => {
        setModalInpostOpen(!isModalInpostOpen)
    }
    const toogleSelectedPoint = (values) => {
        setSelectedPoint(values)
    }
    const handleSetPayment = (values) => {
        setPayment(values)
    }

    useEffect(() => {
            setQuanity(calculateOrderQuanity(order))
    }, [order])

    return <OrderContext.Provider
        value={{
            orders: [order, setOrder],
            handleSetDelivery,
            deliver,
            handleSetCheck,
            checkForm,
            handleSetValidForm,
            isValidForm,
            handleCheckDelivery,
            checkDelivery,
            isModalOpen,
            setModalOpen,
            toogleModalOpen,
            toogleModalInpostOpen,
            isModalInpostOpen,
            setModalInpostOpen,
            quanity,
            toogleSelectedPoint,
            selectedpoint,
            handleSetPayment,
            payment, buyed,handleSetBuyed
        }}>{children}</OrderContext.Provider>;
}

export default OrderContext;
