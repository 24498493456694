import React from 'react';
import SocialMenu from 'components/SocialMenu/SocialMenu';
import styled from 'styled-components';
import Logo from 'components/Logo/Logo';

const StyleFooter = styled.div`
  width: 100vw;
  height: 200px;
  position: relative;
  background-color: ${({ theme }) => theme.honey};
  display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  ${({ theme }) => theme.media.desktop} {
    width: 100%;
    height: 185px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;
const StyleMenuSocial = styled.div`
  width: 80%;
  margin: 0 auto;
  height: 50px;
  margin-top: 10px;
  ${({ theme }) => theme.media.desktop} {
    width: 80%;
    height: 40px;
    margin: 0;
  }
`;
const StyledP = styled.p`
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.font.size.xxs};
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  font-weight: 600;
  ${({ theme }) => theme.media.desktop} {
    text-align: center;
    margin-top: 10px;
    font-size: ${({ theme }) => theme.font.size.xxs};
    font-family: ${({ theme }) => theme.font.family.Montserrat};
    font-weight: 600;
  }
`;
const LogoFooter = styled.div`
  width: 100%;
  height: 100px;
  padding-top: 10px;
  margin-left: 10px;
  display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  z-index: 0;
  ${({ theme }) => theme.media.desktop} {
    width: 100%;
    height: auto;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 0;
  }
`;
const Logos = styled(Logo)`
  width: 49px;
  height: 49px;
  z-index: 1;
`;

const Footer = () => (
  <StyleFooter>
    <LogoFooter>
      <Logos />
    </LogoFooter>
    <StyleMenuSocial>
      <SocialMenu />
    </StyleMenuSocial>
    <StyledP>© 2024 Pasieka Cywińskich. Wszelkie prawa zastrzeżone.</StyledP>
  </StyleFooter>
);
export default Footer;
