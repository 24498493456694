exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-miody-smakowe-js": () => import("./../../../src/pages/miody_smakowe.js" /* webpackChunkName: "component---src-pages-miody-smakowe-js" */),
  "component---src-pages-miody-tradycyjne-js": () => import("./../../../src/pages/miody_tradycyjne.js" /* webpackChunkName: "component---src-pages-miody-tradycyjne-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-produkty-pszczele-js": () => import("./../../../src/pages/produkty_pszczele.js" /* webpackChunkName: "component---src-pages-produkty-pszczele-js" */),
  "component---src-pages-sklep-js": () => import("./../../../src/pages/sklep.js" /* webpackChunkName: "component---src-pages-sklep-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-shop-template-js": () => import("./../../../src/templates/shopTemplate.js" /* webpackChunkName: "component---src-templates-shop-template-js" */)
}

