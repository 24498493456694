import React from "react";
import styled from "styled-components";



const StyledHamburger = styled.button`
    width:50px;
    height:50px;
    border: none;
    background:none;
    z-index:9999;
    position: absolute;
    right: 3px;
    top:30px;
    padding-left: 12px;

${({theme}) => theme.media.desktop} {
    display:none;
}

 `;
const InnerHambuerger = styled.div`
position: relative;
width: 22px;
height: 3px;
background-color: ${({theme, isOpen}) => isOpen ? 'transparent':theme.honey};
transition: background-color .15s ease-in-out;
/* background-color: ${({theme}) => theme.honey}; */

::before, ::after {
    content:'';
    position: absolute;
    display: flex;
    width: 22px;
    height: 3px;
    left: 0;
    background-color: ${({theme, isOpen}) => isOpen ? theme.black : theme.honey};
    transition: transform .25s .15s ease-in-out;
}
::before{
    top: -7px;
    transform: translateY(${({isOpen}) => isOpen ? '7px' : '0'}) rotate(${({isOpen}) => isOpen ? '45deg' : '0'});
}
::after{
    top: 7px;
    transform: translateY(${({isOpen}) => isOpen ? '-7px' : '0'}) rotate(${({isOpen}) => isOpen ? '-45deg' : '0'});
}
`;

// eslint-disable-next-line react/prop-types
const Hamburger = ({isOpen, ...props}) => (

    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledHamburger {...props}>
    <InnerHambuerger isOpen={isOpen} />
    </StyledHamburger>
);
export default Hamburger;